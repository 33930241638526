html, body {
  margin:0;
  padding:0;
  height:100%;
  background-image: url("./Assets/Background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.App {
  text-align: center;
  height: '100%';
}

