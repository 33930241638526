.LoginPage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 25%;
    display: grid;
    align-content: center;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    justify-self: center;
    width: 30%;
    height: 25%;
    padding: 2.5%

}

.IRPage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 25%;
    display: grid;
    align-content: center;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    justify-self: center;
    width: 40%;
    height: auto;
    padding: 2.5%
}

.HomePage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10%;
    display: grid;
    align-content: center;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    justify-self: center;
    width: 90%;
    height: 72.5%;
}

.OrderPage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 10%;
    display: grid;
    align-content: center;
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    justify-self: center;
    width: 90%;
    height: 72.5%;
}



